import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import moment from "moment"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPost = ({ data, pageContext }) => {
  const { changelog } = pageContext
  const { mdx } = data
  const { frontmatter, body } = mdx
  const {
    title,
    date,
    category = "uncategorized",
    progress = "work in progress",
    certainty = 5,
    importance = 5,
    page = false,
    excerpt = "",
  } = frontmatter

  const constructChangelog = () => {
    if (!changelog) return <span></span>

    return (
      <div className="post-changelog">
        <div className="title">Changes to this page</div>
        {changelog.map(log => (
          <div className="log" key={log.hash}>
            <span className="time">{moment(log.date).format("MMM Do YY")}</span>{" "}
            {log.message}
          </div>
        ))}
      </div>
    )
  }
  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <div className="post-container">
        <div className="post-header">
          <h1 className="post-title">{title}</h1>
        </div>
        <article id="markdownBody" className="post-content">
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </article>
        {constructChangelog()}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        slug
        page
        category
      }
      body
    }
  }
`

export default BlogPost
